import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DataFetcher.css';

function Technology() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTechnologyData = async () => {
            try {
                const response = await axios.get('http://attero-news.agger.in/api/technology');
                setArticles(response.data);
            } catch (error) {
                console.error('There was an error fetching competitors data!', error);
                setError('Error fetching competitors data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchTechnologyData();
    }, []);

    const handleImageError = (e) => {
        e.target.src = 'https://via.placeholder.com/150'; // Fallback image URL
    };

    const getImageUrl = (image) => {
        let filename;

        if (typeof image === 'object' && image !== null) {
            filename = image.filename; // Extract filename from object
        } else if (typeof image === 'string') {
            filename = image; // Use the string directly
        } else {
            return 'https://via.placeholder.com/150'; // Fallback image
        }

        return `http://attero-news.agger.in/api/local-image-tech/${filename}`;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return isNaN(date.getTime()) ? 'Invalid date' : date.toLocaleDateString(undefined, options);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="card-container">
            {articles.map((item, index) => (
                <div key={index} className="card">
                    <a href={item.redirectedURL} className="card-link">
                        <img 
                            src={getImageUrl(item.image)} 
                            alt={item.title || 'Image'}
                            className="card-image"
                            onError={handleImageError} 
                        />
                        <div className="card-content">
                            <h2>{item.Title}</h2>
                            <p>{item.Source}</p>
                            <p className="card-date">{formatDate(item.Date)}</p>
                        </div>
                    </a>
                    {item.URL && (
                        <p className="card-source" style={{ color: 'blue' }}>
                            <a href={item.URL} target="_blank" rel="noopener noreferrer">{item.Source}</a>
                        </p>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Technology;

