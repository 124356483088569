
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import DataFetcher from './DataFetcher';
import Login from './components/login';
import DropdownMenu from './DropdownMenu';
import Competitors from './competitors';
import Ministry from './ministry';
import Technology from './technology';
import Fashion from './fashion';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginMessage, setLoginMessage] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    // Check localStorage for the login state
    const storedLoginState = localStorage.getItem('isLoggedIn');
    if (storedLoginState === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setLoginMessage('');
    localStorage.setItem('isLoggedIn', 'true'); // Save login state
  };

  const handleLoginFailure = (message) => {
    setIsLoggedIn(false);
    setLoginMessage(message);
    localStorage.removeItem('isLoggedIn'); // Clear login state
  };

  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          {isLoggedIn && <DropdownMenu onToggle={handleDropdownToggle} />}
          
          <div className="card-container" style={{ paddingLeft: isDropdownOpen ? '240px' : '20px' }}>
            <Routes>
              <Route path="/" element={
                isLoggedIn ? <DataFetcher /> : (
                  <>
                    <Login onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
                    {loginMessage && <p>{loginMessage}</p>}
                  </>
                )
              } />
              <Route path="/competitors" element={<Competitors />} />
              <Route path="/ministry" element = {<Ministry />} />
              <Route path="/technology" element = {<Technology/>} />
              <Route path="/fashion" element = {<Fashion/>} />
            </Routes>
          </div>
        </header>
      </div>
    </Router>
  );
}

export default App;

