import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './DropdownMenu.css';

const DropdownMenu = ({ onToggle }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
        onToggle(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                onToggle(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onToggle]);

    const handleClick = (platform) => {
        navigate(`/${platform.toLowerCase()}`);
        setIsOpen(false);
        onToggle(false);
    };

    const goToMainPage = () => {
        navigate('/'); // Navigate to the main page
        setIsOpen(false);
        onToggle(false);
    };

    return (
        <div className="dropdown" ref={dropdownRef}>
            <div className="menu-icon" onClick={toggleDropdown}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <span className = "menu-text">Menu</span>
            {isOpen && (
                <div className="dropdown-content">
                    <div onClick={goToMainPage} className="dropdown-item">General</div>
                    <div onClick={() => handleClick('ministry')} className="dropdown-item">Policy & Law Updates</div>
                    <div onClick={() => handleClick('technology')} className = "dropdown-item">Technology & Trends</div>
                    <div onClick={() => handleClick('fashion')} className = "dropdown-item">Fashion</div>
                    <div onClick={() => handleClick('competitors')} className="dropdown-item">Competitors</div>
                </div>
            )}
        </div>
    );
};

export default DropdownMenu;

